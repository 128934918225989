import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DS_APP_WRAPPER_CONFIG } from '@design-system/feature/app-wrapper';
import { AppWrapperV2Module } from '@design-system/feature/app-wrapper-v2';
import { DS_SPECIAL_PAGE_HOME_URL } from '@design-system/feature/special-pages';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { BASE_PATH } from './shared/generated';
import { MarketModelRouteGuard } from './shared/guards/market-model-route.guard';
import { SelectUserComponent } from './shared/select-user/select-user.component';
import { AuthService } from './shared/services/auth.serivce';
import { HttpErrorService } from './shared/services/http-error.service';
import { SharedModule } from './shared/shared.module';
import * as fromRootStore from './shared/store/index';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SelectUserComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppWrapperV2Module.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Market Model',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Market Insight',
          appHelpPath: environment.LINKS.APP_HELP_PATH,
          appNewsPath: environment.LINKS.APP_NEWS_PATH,
          useCSISurvey: environment.FEATUREFLAGS.USE_CSI_SURVEY === 'true',
        }),
      },
    ]),
    DsBreadcrumbsModule,
    StoreModule.forRoot(fromRootStore.rootReducer, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreModule.forFeature(
      fromRootStore.featureName,
      fromRootStore.rootReducer,
    ),
    EffectsModule.forRoot([fromRootStore.RootEffects]),
    HttpClientModule,
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),

    SharedLibFeatTranslationModule,
    SharedModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      connectInZone: true,
    }),
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    TranslateModule,
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    {
      provide: BASE_PATH,
      useFactory: () => environment.palfinger_api_management + '/market-model',
      multi: false,
    },
    {
      provide: DS_SPECIAL_PAGE_HOME_URL,
      useFactory: () => environment.LINKS.HOME,
    },
    // User Profile
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    MarketModelRouteGuard,
    AuthService,
    HttpErrorService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
  ) {
    this.translateService.setDefaultLang('en');
  }
}
