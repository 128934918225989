import { Component, inject } from '@angular/core';
import { Menu } from '@design-system/feature/app-wrapper';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { MarketModelRouteGuard } from './shared/guards/market-model-route.guard';
import { RootSelectors } from './shared/store';

interface SidebarRoute {
  url: string;
  icon: string;
  label: string;
}

const routes: SidebarRoute[] = [
  {
    url: 'tasks/provisionings',
    icon: 'assignment',
    label: 'task_assignment.provisionings.default',
  },
  {
    url: 'tasks/coordinators',
    icon: 'record_voice_over',
    label: 'task_assignment.coordinators.default',
  },
  {
    url: 'tasks/inputassignment',
    icon: 'group',
    label: 'task_assignment.input_assignment.default',
  },
  {
    url: 'marketinput/regionproductstatus',
    icon: 'assessment',
    label: 'market_input.region_product_status.default',
  },
  {
    url: 'marketinput/inputstatus',
    icon: 'dialpad',
    label: 'market_input.input_state.default',
  },
];

@Component({
  selector: 'mm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  menu$: Observable<Menu>;

  readonly routesInformation$ = inject(Store).select(
    RootSelectors.routesInformation,
  );

  constructor() {
    this.menu$ = this.routesInformation$.pipe(
      map(
        (routesInformation) =>
          routesInformation &&
          routes.filter((r) =>
            MarketModelRouteGuard.hasRoute(routesInformation, r.url),
          ),
      ),
      map((r) => ({
        title: 'market_model.default',
        titleIcon: 'pd-market-model',
        navigation:
          r?.map((routeItem) => ({
            title: routeItem.label,
            icon: routeItem.icon,
            routerLink: `/${routeItem.url}`,
          })) || [],
      })),
    );
  }
}
