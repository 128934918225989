import { Injectable, inject } from '@angular/core';
import { DsSnackbar } from '@design-system/feature/snackbar';
import { UserService as PaldeskUserService } from '@features/auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatus } from '@ui-kit/loading-status';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  switchMapTo,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  CacheService,
  CountryService,
  MarketModelRoleService,
  MarketModelRoleType,
  MarketProvisioningService,
  PartnerService,
  ProductService,
  RegionService,
  UserService,
} from '../generated';
import { RoutingService } from '../services/routing.service';
import { RootActions } from './root.actions';
import { RootState } from './root.reducer';
import { RootSelectors } from './root.selectors';

@Injectable()
export class RootEffects {
  private actions$: Actions = inject(Actions);
  private rootStore: Store<RootState> = inject(Store);

  loadCurrentProvisioningId$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadCurrentProvisioningId),
      exhaustMap(() =>
        this.marketProvisioningService.apiMarketProvisioningCurrentIdGet().pipe(
          map((id) =>
            RootActions.LoadCurrentProvisioningIdSuccess({ payload: id }),
          ),
          catchError((err) =>
            of(RootActions.LoadCurrentProvisioningIdError({ payload: err })),
          ),
        ),
      ),
    ),
  );

  loadMarketModelRoles$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadMarketModelRoles),
      exhaustMap(() =>
        this.marketModelRoleService.apiMarketModelRoleGet().pipe(
          map((roles) =>
            RootActions.LoadMarketModelRolesSuccess({ payload: roles }),
          ),
          catchError((err) =>
            of(RootActions.LoadMarketModelRolesError({ payload: err })),
          ),
        ),
      ),
    ),
  );

  getRoutesInformation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.GetRoutesInformation),
      switchMap(() =>
        combineLatest([
          this.rootStore
            .select(RootSelectors.currentProvisioningId)
            .pipe(filter((x) => x != null)),
          this.rootStore
            .select(RootSelectors.marketModelRolesLoadingStatus)
            .pipe(
              switchMap((loading) =>
                loading === LoadingStatus.Success
                  ? this.rootStore.select(RootSelectors.marketModelRoles)
                  : EMPTY,
              ),
              filter((x) => !!x),
              map((x) => x.map((r) => r.roleType)),
            ),
          of(this.userServicePaldesk.currentUser).pipe(filter((x) => !!x)),
        ]),
      ),
      exhaustMap(([id, roles]) =>
        of(
          RootActions.GetRoutesInformationSuccess({
            payload: this.routingService.getRoutesInformation(
              // tslint:disable-next-line:no-useless-cast
              roles as MarketModelRoleType[],
              id || -1,
            ),
          }),
        ),
      ),
    ),
  );

  loadPartners$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadPartners),
      exhaustMap(() =>
        this.partnerService.apiPartnerGet().pipe(
          map((data) => RootActions.LoadPartnersSuccess({ payload: data })),
          catchError((err) =>
            of(RootActions.LoadPartnersError({ payload: err })),
          ),
        ),
      ),
    ),
  );

  loadUsers$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadUsers),
      map((x) => x.payload),
      switchMap((partnerId) =>
        this.userService.apiUserBpmCompanyIdGet(partnerId || -1).pipe(
          map((data) => RootActions.LoadUsersSuccess({ payload: data })),
          catchError((err) => of(RootActions.LoadUsersError({ payload: err }))),
        ),
      ),
    ),
  );

  showSnackbarTranslated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowSnackbarTranslated),
        switchMap((payload) =>
          combineLatest([
            of(payload.snackbarType),
            this.translateService.get(payload.key, payload.interPolateParams),
          ]).pipe(
            tap(([snackbarType, message]) =>
              this.snackbar.queue(message, {
                type: snackbarType,
              }),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  showSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowSnackbar),
        tap((payload) =>
          this.snackbar.queue(payload.message, {
            type: payload.snackbarType,
          }),
        ),
      ),
    { dispatch: false },
  );

  saveFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.SaveFile),
        map((payload) => saveAs(payload.data, payload.filename)),
      ),
    { dispatch: false },
  );

  loadRegions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadRegions),
      switchMapTo(
        this.rootStore
          .select(RootSelectors.currentProvisioningId)
          .pipe(filter((x) => x != null)),
      ),
      exhaustMap((marketProvisioningId) =>
        this.regionService
          .apiRegionMarketProvisioningIdGet(marketProvisioningId || -1)
          .pipe(
            map((data) => RootActions.LoadRegionsSuccess({ payload: data })),
            catchError((err) =>
              of(RootActions.LoadRegionsError({ payload: err })),
            ),
          ),
      ),
    ),
  );

  loadProducts$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadProducts),
      switchMapTo(
        this.rootStore
          .select(RootSelectors.currentProvisioningId)
          .pipe(filter((x) => x != null)),
      ),
      exhaustMap((marketProvisioningId) =>
        this.productService
          .apiProductMarketProvisioningIdGet(marketProvisioningId || -1)
          .pipe(
            map((data) =>
              RootActions.LoadProductsSuccess({
                payload: data,
              }),
            ),
            catchError((err) =>
              of(RootActions.LoadProductsError({ payload: err })),
            ),
          ),
      ),
    ),
  );

  loadCountriesAndOems$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadCountriesAndOems),
      switchMapTo(
        this.rootStore
          .select(RootSelectors.currentProvisioningId)
          .pipe(filter((x) => x != null)),
      ),
      exhaustMap((marketProvisioningId) =>
        this.countryService
          .apiCountryAllMarketProvisioningIdGet(marketProvisioningId || -1)
          .pipe(
            map((data) =>
              RootActions.LoadCountriesAndOemsSuccess({
                payload: data,
              }),
            ),
            catchError((err) =>
              of(RootActions.LoadCountriesAndOemsError({ payload: err })),
            ),
          ),
      ),
    ),
  );

  loadUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.LoadUserSettings),
      exhaustMap(() =>
        this.userService.apiUserUserSettingsGet().pipe(
          map((data) =>
            RootActions.LoadUserSettingsSuccess({
              payload: data,
            }),
          ),
          catchError((err) =>
            of(RootActions.LoadUserSettingsError({ payload: err })),
          ),
        ),
      ),
    ),
  );

  updateUserSettingsPartial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.UpdateUserSettingsPartial),
      withLatestFrom(this.rootStore.select(RootSelectors.userSettings)),
      map(([updateAction, existing]) =>
        RootActions.UpdateUserSettings({
          payload: { ...existing, ...updateAction.payload },
        }),
      ),
    ),
  );

  updateUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.UpdateUserSettings),
      exhaustMap(({ payload }) =>
        this.userService.apiUserUserSettingsPost(payload).pipe(
          // eslint-disable-next-line sonarjs/no-identical-functions
          map((data) =>
            RootActions.LoadUserSettingsSuccess({
              payload: data,
            }),
          ),
          catchError((err) =>
            of(RootActions.LoadUserSettingsError({ payload: err })),
          ),
        ),
      ),
    ),
  );

  clearCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.ClearCache),
      exhaustMap(() =>
        this.cacheService.apiCacheClearPost().pipe(
          map(() => RootActions.ClearCacheSuccess()),
          catchError((error) => [RootActions.ClearCacheFailed({ error })]),
        ),
      ),
    ),
  );

  constructor(
    private marketProvisioningService: MarketProvisioningService,
    private marketModelRoleService: MarketModelRoleService,
    private routingService: RoutingService,
    private userServicePaldesk: PaldeskUserService,
    private userService: UserService,
    private partnerService: PartnerService,
    private snackbar: DsSnackbar,
    private translateService: TranslateService,
    private regionService: RegionService,
    private productService: ProductService,
    private countryService: CountryService,
    private cacheService: CacheService,
  ) {}
}
